<template>
  <div cla>
    <!-- Show the header option -->
    <div class="d-flex justify-end mb-6">
      <!-- Show the save button -->
      <v-btn
        depressed
        color="primary"
        :loading="isMakingRequest"
        :disabled="isMakingRequest"
        @click="handleSubmitRequest"
      >
        <v-icon left>
          refresh
        </v-icon>

        Save
      </v-btn>
    </div>

    <v-subheader
      class="d-flex justify-space-between primary white--text"
    >
      Cost by Post Type
    </v-subheader>

    <v-row class="white mt-0 px-4 py-4">
      <v-col
        cols="12"
        md="6"
        lg="3"
        v-for="keyName in Object.keys(formData)"
        :key="keyName"
      >
        <v-text-field
          v-model="formData[keyName]"
          :hide-details="!$v.formData[keyName].$anyError"
          :error-messages="$v.formData[keyName].$anyError ? ['Please enter a valid amount'] : null"
          @blur="$v.formData[keyName].$touch"
          :label="capitalizeString(keyName)"
          :disabled="isMakingRequest"
          outlined
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
// Import helper functions
import { numeric, minValue, maxValue } from "vuelidate/lib/validators"

// Define generator function
const generateForm = () => ({
  story: "",
  photo: "",
  video: "",
  carousel: ""
})

// Export the SFC
export default {
  // Name of the component
  name: "InfluencerOutreachPrices",

  // Accept incoming data from parent
  props: {
    influencer: {
      type: Object,
      required: true
    },

    data: {
      type: Object,
      required: true
    }
  },

  // Define local data variables
  data: () => ({
    // Whether or not if we're making a network request right now
    isMakingRequest: false,

    // The form inputs
    formData: generateForm()
  }),

  // Define vuelidate validations
  validations: {
    formData: {
      story: {
        numeric,
        minValue: minValue(0),
        maxValue: maxValue(1_000_000_000)
      },
      photo: {
        numeric,
        minValue: minValue(0),
        maxValue: maxValue(1_000_000_000)
      },
      video: {
        numeric,
        minValue: minValue(0),
        maxValue: maxValue(1_000_000_000)
      },
      carousel: {
        numeric,
        minValue: minValue(0),
        maxValue: maxValue(1_000_000_000)
      }
    }
  },

  // Define local method functions
  methods: {
    /**
     * Make a network request to update the prices value for this influencer
     *
     * @returns {void}
     */
    async handleSubmitRequest() {
      // Validate the inputs
      await this.$v.formData.$touch()

      // If there are any errors
      if (this.$v.formData.$anyError) {
        // Stop further execution
        return
      }

      // If none of the inputs have a value
      if (Object.values(this.formData).filter(v => v).length === 0) {
        // Stop further execution
        return
      }

      // Otherwise, show a loader
      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId)
      this.isMakingRequest = true

      // Make the network request
      try {
        // Map the data from input
        const formData = {
          story: this.formData.story ? Number(this.formData.story) : null,
          photo: this.formData.photo ? Number(this.formData.photo) : null,
          video: this.formData.video ? Number(this.formData.video) : null,
          carousel: this.formData.carousel ? Number(this.formData.carousel) : null
        }

        // Use the helper function
        await axios({
          url: `/api/influencer-outreach/${this.data.model.id}/influencers/${this.influencer.id}/prices`,
          method: "POST",
          data: formData
        })

        // Show a message
        this.$store.dispatch("toasts/add", { text: "Influencer prices updated!" })

        // Dispatch the Vuex action
        this.$store.dispatch("influencerOutreach/updatePrices", { influencer: this.influencer, prices: formData })
      }
      // Catch the error
      catch (error) {
        // Log the error to console
        logger({ type: "InfluencerOutreach/InfluencerPrices Submit Error", error })

        // Show the error message
        this.$store.dispatch("toasts/add", { text: error.response?.data?.message || "An error occurred" })
      }
      // Nevertheless
      finally {
        // Hide the loader
        this.$store.dispatch("loaders/remove", loaderId)
        this.isMakingRequest = false
      }
    }
  },

  /**
   * As soon as the component data is ready
   *
   * @returns {void}
   */
  created() {
    // If we have some data for this influencer already
    if (this.influencer.prices) {
      // Replace those values
      this.formData.story = this.influencer.prices.story || ""
      this.formData.photo = this.influencer.prices.photo || ""
      this.formData.video = this.influencer.prices.video || ""
      this.formData.carousel = this.influencer.prices.carousel || ""
    }
  }
}
</script>
